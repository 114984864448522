<template lang="html">
  <div class="gallery">
    <div class="gallery-grid" :style="formatedGap">
      <div class="gallery-item" v-for="(item, itemIndex) in items" ref="galleryItems" @click="redirectToItem(routeTo, itemIndex)"> <!-- function building the link from item object -->
        <div class="item-layout">
          <div class="item-layout-image">
            <svgIcon :name="item.icon"/>
          </div>
          <div class="item-layout-info">
            <div class="gallery-label" v-if="item.label">
              <p class="gallery-label-details">{{item.label.price}} $</p>
            </div>
            <!-- <img class="gallery-image" v-lazy="item.previewImg.path + '-1600px'" :data-srcset="srcSetAtt(itemIndex)" :alt="item.previewImg.desc"> -->
            <div class="gallery-details-wrapper">
              <h1 class="gallery-item-title">{{item.title}}</h1>
              <p class="gallery-item-desc">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router"

export default {
  name: "gallery",
  props: {
    items: {
      type: Array
    },
    gap: {
      type: String
    },
    routeTo: {
      type: String,
      required: true
    }
  },
  data(){
    return{
    }
  },
  methods: {
    srcSetAtt(indexArg){
      return this.items[indexArg].previewImg.path + "-400px.jpg 400w, " + this.items[indexArg].previewImg.path + "-800px.jpg 800w, " + this.items[indexArg].previewImg.path + "-1200px.jpg 1200w, " + this.items[indexArg].previewImg.path + "-1600px.jpg 1600w"
    },
    redirectToItem(argRoute, index){
      let linkRoute = this.items[index].link
      let linkComp = this.items[index].comp
      let paramObj = this.items[index]
      router.push({
        path:`/${argRoute}/${linkRoute}`,
        // params: {paramObj},
        component: linkComp
      } )
    }
  },
  computed: {
    formatedGap: function(){
      return "grid-gap: " + this.gap + ";"
    }
  }
}
</script>

<style lang="css">
.gallery{
}

.gallery-grid{
  display: grid;
  justify-content: center;
  padding-bottom: calc( var(--size-unit) * 20);
  grid-template-columns: repeat(auto-fill, minmax(240px, 360px));
}
.gallery-item{
  position: relative;
  min-width: 160px;
  box-shadow: 0 0 0 0;
  cursor: pointer;
  transition: box-shadow var(--lin-slow), transform var(--lin-slow);
}
.gallery-item:hover{
  /* transform: translate(4px, -4px); */
  transition: box-shadow var(--lin-cool), transform var(--lin-cool);
  box-shadow: 0 0 0 1px var(--over-bg), -4px 4px 6px 1px var(--color-prim);
}
.gallery-item-title,
.gallery-item-desc{
}
.gallery-item-title {
  font-size: var(--f-size-4);
  font-weight: var(--font-wei-1);
}
.gallery-image{
  width: 100%;
  height: auto;
}
.gallery-item a{
  display: flex;
  flex-direction: column;
}
.gallery-details-wrapper{
  padding: 0 calc( var(--size-unit) *2 );
}
.gallery-label{
  position: absolute;
  background-color: var(--over-bg);
  width: auto;
  height: auto;
  top: -2px;
  right: calc(var(--size-unit)*4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
}
.gallery-label-details{
  font-family: var(--font-fam-2);
  font-weight: var(--font-wei-2);
  padding: 16px;
}
.item-layout-image{
  padding: calc( var(--size-unit) *2 );
}
.--mode-light.--prim-bright .item-layout-image svg{
  --color-prim: var(--color-prim-dark);
}
.item-layout-image svg{
  height: 64px;
  width: 64px;
  fill: none;
  stroke: var(--color-prim);
  transition: stroke var(--lin-slow);
}
.gallery-item:hover .item-layout-image svg,
.item-layout-image svg:hover{
  stroke: currentColor;
  transition: stroke var(--lin-cool);
}

.item-layout-info{

}
</style>
