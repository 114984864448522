<template lang="html">
  <section class="page interactive">
    <div class="container">
      <gallery routeTo="interactive" :items="items" gap="40px"/>
    </div>
  </section>
</template>

<script>
import gallery from '@/components/gallery'

export default {
  name: "interactive",
  components: {
    gallery
  },
  data(){
    return{
      items: [
        {
          title: "Hyper Snake",
          desc: "The legendary arcade classic game revisited with some cool twists.",
          icon: "snake",
          link: "snake",
          comp: "snake"
        },
        {
          title: "Psychedelic slider",
          desc: "A psychedelic slider designed to make you lose focus.",
          icon: "psycheSlider",
          link: "disturbing-slider",
          comp: "disturbingSlider",
        },
        {
          title: "Abstractor",
          desc: "Parametric generator of geometric shapes artworks.",
          icon: "art",
          link: "art-generator-v1",
          comp: "artGeneratorV1",
        },
        // {
        //   title: "Semantic Bot",
        //   desc: "Explore relations between words.",
        //   icon: "bot",
        //   link: "semantic-bot",
        //   comp: "semanticBot"
        // },
      ]
    }
  }
}
</script>
<style>
.interactive .container{
  padding: 0 var(--container-padding);
  position: relative;
  margin: 0 auto;
  top: var(--nav-h);
}
</style>
